import axios from 'axios';
import { Toast } from 'vant';
import router from '../router';
import qs from "qs";

//测试库域名 https://10080.hsx777.cn/
//正式库域名 https://ck.jiihee.com/

axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'http://localhost:3032/':'https://ck.jiihee.com/'
  // request拦截器
axios.interceptors.request.use(config => {
  config.headers['tokenId'] = localStorage.getItem('token')
  config.headers['appCode'] = "CAR_ADMIN_CC";
  config.headers['version'] = "0.0.1";
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
  if(config.method === 'post'&&config.dataType !== 'json') {
    config.data = qs.stringify({...config.data})
  }

  if(config.dataType === 'json'){
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
  }
  return config
}, error => {
  Promise.reject(error)
})

axios.interceptors.response.use(response => {

  const res = response.data;
  if (res.code === 10001011) {//token失效处理
    //Toast.fail(res.message)
    router.push({ name: `login`,query:{returnUrl:router.history.current.path}})
    return Promise.reject('error')
  } else {
    return response.data
  }

})

export default axios
